<template>
  <div class="container">
    <div class="header">全面满足，不断超越</div>
    <div class="card1">
      <div class="card-title-wrap d-f j-c">
        <div class="card-title">精选案例</div>
      </div>
      <div class="card-info d-f j-c a-a">
        <el-carousel arrow="never" indicator-position="none" :interval="2000"
          type="card" height="2.385417rem">
          <el-carousel-item>
            <div class="ban ban1 d-f j-c a-a">
              <img class="s-img1" src="../../static/img/case-show/s-xys.png"
                alt="" />
              <img class="s-img2" src="../../static/img/case-show/s-xys-t.png"
                alt="" />
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="ban ban2 d-f j-c a-a">
              <img class="s-img1" src="../../static/img/case-show/s-ny.png"
                alt="" />
              <img class="s-img2" src="../../static/img/case-show/s-ny-t.png"
                alt="" />
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="ban ban3 d-f j-c a-a">
              <img class="s-img1" src="../../static/img/case-show/s-sq.png"
                alt="" />
              <img class="s-img2" src="../../static/img/case-show/s-sq-t.png"
                alt="" />
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="ban ban4 d-f j-c a-a">
              <img class="s-img1" src="../../static/img/case-show/s-zd.png"
                alt="" />
              <img class="s-img2" src="../../static/img/case-show/s-zd-t.png"
                alt="" />
            </div>
          </el-carousel-item>
          <!-- <el-carousel-item >
                        <div class="ban ban5 d-f j-c a-a">
                            <img src="../../static/img/case-show/ban4.png" alt="">
                        </div>
                    </el-carousel-item> -->
        </el-carousel>
      </div>
    </div>
    <div class="card2">
      <div class="title d-f j-c a-a govtitle">
        <div class="line1"></div>
        <div class="small-square"></div>
        <div class="big-square"></div>
        <div class="title-info">政府案例</div>
        <div class="big-square"></div>
        <div class="small-square"></div>
        <div class="line2"></div>
      </div>
      <div class="card2-info">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in govArr" :key="i">
              <img :src="
                  require('../../static/img/case-show/' + item.imgurl + '.png')
                " alt="" />
              <div class="detail">
                <div class="detail-title">{{ item.title }}</div>
                <div class="msg">{{ item.content }}</div>
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
        <div class="swiper-button-prev swiper-arw"></div>
        <div class="swiper-button-next swiper-arw"></div>
      </div>
    </div>
    <div class="card3">
      <div class="title d-f j-c a-a title2">
        <div class="line1"></div>
        <div class="small-square"></div>
        <div class="big-square"></div>
        <div class="title-info">学校案例</div>
        <div class="big-square"></div>
        <div class="small-square"></div>
        <div class="line2"></div>
      </div>
      <div class="card3-content">
        <div class="swiper-container2">
          <div class="swiper-wrapper">
            <div class="card3-content-item swiper-slide"
              v-for="(item, index) in schoolArr" :key="index">
              <div class="img">
                <img :src="item.imgurl" alt="" />
              </div>
              <div class="detail">
                <div class="detail-title">{{ item.title }}</div>
                <div class="msg">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev2 swiper-arw"></div>
        <div class="swiper-button-next2 swiper-arw"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";

export default {
  data () {
    return {
      mySwiper: null,
      mySwiper2: null,
      govArr: [
        {
          imgurl: "ban1",
          title: "郑州航空港产业聚集区智能管理综合服务...",
          content:
            "郑州航空港产业聚集区智能管理综合服务系统是航空港区为了更好的展示航空港区企业和风采，特邀请我司为其开发的一套数据管理系统。该系统主要包括地图基础数据库建设和大场景数字化采集与处理工作。",
        },
        {
          imgurl: "ban2",
          title: "孟州市农产品质量安全追溯点建设项目",
          content:
            "该项目是为了农产品质量安全达到省级标准特建设的示范点项目。加快推进了当地的农产品安全建设，使当地的农业监管实现了从无到有的突破。对当地的农业发展起到了很大的推动作用。",
        },
        {
          imgurl: "ban3",
          title: "平顶山市国土资源局视频会议项目",
          content:
            "平顶山市国土局为了服务群众，与我司合作。我司为其设计一套高清视频会议系统，此系统主要是方便政务对接，上层与省厅联系，下层与各个负责的管局对接，上下贯通，很好的解决客户需求。",
        },
        {
          imgurl: "ban4",
          title: "安阳市国土资源局机房建设项目",
          content:
            "通过对机房的改造，使得布线及标识完整，方便维护及调试。改造后整个网络系统满足客户需求，提高了网络运行效率，对网络的升级、管理、维护起到了决定性的支持作用。",
        },
        {
          imgurl: "ban5",
          title: "长垣县国土资源局准化机房建设工程",
          content:
            "整体机房改造包括机房墙体部分、机房温度系统、机房防雷系统、机房消防系统、机房供配电系统等整体改进。通过对机房的改造，使得整体机房操作简单、布线及标识完整，方便维护及调试。",
        },
        {
          imgurl: "ban6",
          title: "巩义市不动产登记信息平台建设项目",
          content:
            "巩义市不动产登记信息平台建设是在不动产系统整体省级改造的大背景下开始的。后经我司整体为其部署设计，推进巩义不动产信息化建设，积极对接上级平台系统，完成巩义国土局建设要求。",
        },
        {
          imgurl: "ban7",
          title: "柘城县不动产登记中心信息平台建设项目",
          content:
            "柘城国土局为了改善整体信息化落后，导致办理速度和效率过低的局面，根据省级文件要求提高信息化建设的总体要求，经我司为其部署设计，完成柘城不动产的信息化建设目标，达到整体信息化建设的要求。",
        },
        {
          imgurl: "ban8",
          title: "河南省农业厅农产品安全追溯平台建设...",
          content:
            "我司承接是全省水产品安全检测以及各地市的水产品监测站的建设。多次与省厅对接检测方案以及监测站建设要求。经多次对接后确定实施方案，到后来在全省各地市开始部署，圆满完成省厅的建设要求。",
        },
        {
          imgurl: "ban9",
          title: "漯河市公共资源交易平台系统选取运维...",
          content:
            "漯河市公用资源交易中心的整体运维工作主要是承担交易平台系统运行的日常维护、运行中的漏洞修复、补丁升级、功能性升级和招标人、投标人、评标专家、代理机构定期及长期的培训等。",
        },
        {
          imgurl: "ban10",
          title: "濮阳县国土资源局不动产信息平台软件及...",
          content:
            "为了改善整体信息化落后，导致办理速度和效率过低的局面，根据省级文件要求提高信息化建设的总体要求，经我司整体为其部署设计，完成濮阳县国土资源局的信息化建设目标，达到整体信息化建设的要求。",
        },
        {
          imgurl: "ban11",
          title: "襄城县市民之家智能化系统项目",
          content:
            "襄城县市民之家位于河南省许昌市襄城县烟城路中冠龙城东侧襄城县文博中心内，项目内容主要分为网络高清视屏监控系统、监控机房及大屏显示系统建设、网络综合布线系统、公共区域背景音乐系统等。",
        },
        {
          imgurl: "ban12",
          title: "河南省卫健委出入库安全管理系统",
          content:
            "河南省卫生健康委员会位于河南省郑州市金水区金水东路，项目内容共计分为两部分：大门口两侧各两通道双向通行人脸识别闸机通道和地下车库出入口人工智能无人值守停车场管理系统。",
        },
        {
          imgurl: "ban13",
          title: "尉氏县创建省级农产品质量安全县项目",
          content:
            "实现了整体农产品质量安全的可追溯，可溯源。顺利通过了省级安全示范县的创建工作。得到了领导和使用部门的一致好评。为后期创建国家安全示范县打下了牢固的基础。",
        },
        {
          imgurl: "ban14",
          title: "襄城县国土资源局信息系统平台建设项目",
          content:
            "实现了襄城县不动产登记局的整体信息化建设，为更好的完成全县的不动产登记打下了坚实的基础。实现了端到端的全面覆盖，实现了群众少跑路信息多跑路的目标。得到了领导和群众的一直好评。",
        },
      ],
      schoolArr: [
        {
          imgurl: require("../../static/img/case-show/school-logo-01.png"),
          title: "商丘工学院",
          content:
            "商丘工学院服务器存储建设项目，通过我们工程师更加科学的设计，使客户的资源利用率提高了30%，节省了很多不必要的硬件设备，使成本大大减低。",
        },
        {
          imgurl: require("../../static/img/case-show/school-logo-02.png"),
          title: "郑州大学西亚斯学院",
          content:
            "郑州大学西亚斯学院项目内容主要分为：监控机房及大屏显示系统、基础装修工程、整体模块化机柜系统、机房配电系统、防雷接地工程、消防系统等。",
        },
        {
          imgurl: require("../../static/img/case-show/school-logo-03.png"),
          title: "河南经贸职业技术学院",
          content:
            "该项目是专门为服务学生就业而建设的,涵盖了大数据管理的教学与实践管理的专门性教学系统。该项目是以计算节点服务器为依托的综合管控平台。",
        },
        {
          imgurl: require("../../static/img/case-show/school-logo-04.png"),
          title: "商丘学院应用科技学院",
          content:
            "商丘学院应用科技学院项目内容主要分为：整体模块化机柜系统、监控机房及大屏显示系统、基础装修工程、机房配电系统、防雷接地工程、消防系统等。",
        },
      ],
    };
  },
  methods: {
    onResize () {
      this.mySwiper.update();
      this.mySwiper2.update();
    },
  },
  mounted () {
    window.addEventListener("resize", this.onResize);
    this.mySwiper = new Swiper(".swiper-container", {
      // direction: 'vertical', // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      // pagination: {
      //   el: '.swiper-pagination',
      // },
      slidesPerView: 3,
      spaceBetween: 20,
      // centeredSlides : true,
      // updateOnWindowResize: true,
      // 如果需要前进后退按钮
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
    });
    this.mySwiper2 = new Swiper(".swiper-container2", {
      loop: true, // 循环模式选项
      slidesPerView: 4,
      spaceBetween: 20,
      navigation: {
        prevEl: ".swiper-button-prev2",
        nextEl: ".swiper-button-next2",
      },
    });
  },
};
</script>
<style lang="scss" scoped>
.container {
  min-width: 1200px;
  background: #f6f8fb;
  .header {
    // height: 2.25rem;
    font-size: 0.25rem;
    font-family: PingFang SC;
    font-weight: 400;
    // line-height: 0.973958rem;
    color: #000000;
    opacity: 0.87;
    text-align: center;
    padding: 0.416667rem 0 0.208333rem 0;
  }
  .card1 {
    .card-title-wrap {
      // margin-bottom: 0.234375rem;;
      margin-bottom: -0.15625rem;
    }
    .card-title {
      height: 0.234375rem;
      font-size: 0.166667rem;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 0.234375rem;
      color: #000000;
      opacity: 0.87;
      text-align: center;
      position: relative;
      margin-bottom: 0.338542rem;
      // padding-bottom: 0.234375rem;
      &::after {
        content: "";
        display: block;
        width: 0.166667rem;
        height: 2px;
        background: #000000;
        opacity: 0.87;
        position: absolute;
        left: -42px;
        top: 50%;
      }
      &::before {
        content: "";
        display: block;
        width: 0.166667rem;
        height: 2px;
        background: #000000;
        opacity: 0.87;
        position: absolute;
        right: -42px;
        top: 50%;
      }
    }
    .card-info {
      // height: 3.010417rem;
      .s-img2 {
        display: none;
      }
      .is-active .s-img2 {
        display: block;
      }
      .is-active .s-img1 {
        display: none;
      }
      // padding-top: 0.208333rem;
      .ban {
        width: 3.75rem;
        height: 2.291667rem;
        background-size: 100% 100%;
        img {
          // width: 1.041667rem;
          // height: 0.291667rem;
        }
      }
    }
  }
  .card2 {
    .card2-info {
      position: relative;
      &:hover .swiper-arw {
        visibility: visible;
      }
      .swiper-container {
        text-align: center;
        padding: 10px 0;
      }
      .swiper-slide {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
        opacity: 1;
        border-radius: 4px;
        // width: 2.015625rem;
        height: 1.979167rem;
        // margin: 0 0.052083rem;
      }
      // height: 2.03125rem;
      padding: 0 1.875rem;
      img {
        // width: 2.015625rem;
        width: 100%;
        height: 1.09375rem;
      }
      .detail {
        height: 0.885417rem;
        // width: 2.010417rem;
        width: 100%;
        // width: 1.854167rem;
        padding: 0.104167rem;
        background: white;
        text-align: left;
        .detail-title {
          font-size: 0.09375rem;
          font-family: PingFang SC;
          font-weight: 600;
          line-height: 0.130208rem;
          color: #000000;
          opacity: 0.87;
          width: 100%;
          text-align: center;
          padding-bottom: 0.052083rem;
        }
        .msg {
          font-size: 0.072917rem;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 0.114583rem;
          color: #000000;
          opacity: 0.6;
        }
      }
    }
  }
  .card3 {
    padding-bottom: 0.3125rem;
    .card3-content {
      position: relative;
      padding: 0 1.875rem;
      &:hover .swiper-arw {
        visibility: visible;
      }
      .swiper-container2 {
        text-align: center;
        padding: 10px 0;
        overflow: hidden;
      }
      .swiper-slide {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        height: 1.28125rem;
      }
      .card3-content-item {
        width: 1.484375rem;
        height: 1.28125rem;
        background: #fff;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        text-align: center;
        padding-top: 0.15625rem;
        margin: 0 0.104167rem 0.104167rem 0;
        .img {
          align-items: center;
          margin-bottom: 0.104167rem;
        }
        .detail {
          padding: 0 0.104167rem;
          .detail-title {
            font-size: 0.09375rem;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 0.130208rem;
            color: #000000;
            opacity: 0.87;
            padding-bottom: 0.052083rem;
          }
          .msg {
            font-size: 0.072917rem;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 0.114583rem;
            color: #000000;
            opacity: 0.6;
            text-align: left;
            min-height: 94px;
          }
        }
      }
    }
  }
}

.title {
  // height: 1.067708rem;
  padding: 0.416667rem 0;
  .big-square {
    width: 0.088542rem;
    height: 0.088542rem;
    background: #4285f4;
    opacity: 1;
    margin: 10px;
    transform: rotate(45deg);
  }
  .small-square {
    width: 0.052083rem;
    height: 0.052083rem;
    background: #4285f4;
    opacity: 0.38;
    transform: rotate(45deg);
  }
  .title-info {
    font-size: 0.166667rem;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 45px;
    color: #000000;
    opacity: 0.87;
  }
  .line1 {
    width: 40px;
    height: 2px;
    background: linear-gradient(
      270deg,
      rgba(66, 133, 244, 0.38) 0%,
      rgba(66, 133, 244, 0) 100%
    );
    opacity: 1;
    margin-right: 4px;
  }
  .line2 {
    width: 40px;
    height: 2px;
    background: linear-gradient(
      270deg,
      rgba(66, 133, 244, 0) 0%,
      rgba(66, 133, 244, 0.38) 100%
    );
    opacity: 1;
    margin-left: 4px;
  }
}
.title2 {
  padding-bottom: 0.364583rem;
}
.govtitle {
  padding-top: 0.625rem;
  padding-bottom: 0.364583rem;
}
.swiper-arw {
  position: absolute;
  outline: none;
  width: 58px;
  height: 58px;
  &::after {
    content: "";
  }
  visibility: hidden;
}
.swiper-button-prev,
.swiper-button-prev2 {
  background-image: url("../../static/img/case-show/arw2-l.png");
  background-size: 100% 100%;
  left: 1.515625rem;
}
.swiper-button-prev2,
.swiper-button-next2 {
  width: 0.302083rem;
  height: 0.302083rem;
  top: 50%;
  margin-top: -0.151042rem;
}
.swiper-button-next,
.swiper-button-next2 {
  background-image: url("../../static/img/case-show/arw1-r.png");
  background-size: 100% 100%;
  right: 1.515625rem;
}

.el-carousel {
  width: 80%;
}
.el-carousel__item {
  img {
    width: 3.75rem;
    height: 2.291667rem;
  }
}
</style>